.container-fluid{
  padding: 0 !important
}
.dashboard-pagination {
  height: calc(100vh - 82px);
  overflow: auto;

  @media (max-height : 845px) {
    height: calc(100vh - 106px);
    overflow: auto;
  }
}

.dashboard-main-row {
  height: calc(100vh - 82px - 20px);
  overflow: auto;

  @media (max-width:900px) {
    height: calc(100vh - 82px - 30px);
    overflow: auto;
  }
}

.dashboard-row {
  padding: 16px 0px;

  .deposit-col {
    padding-left: 0;
    margin: 1% 0;

    @media (min-width:770px) and (max-width:1199px) {
      margin-bottom: 10px;
    }

    @media (max-width:770px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .user-col {
    padding-left: 0;

    @media (min-width:770px) and (max-width:1199px) {
      margin-bottom: 10px;
    }

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .withdraw-col {
    padding: 0;

    @media (min-width:991px) and (max-width:1199px) {
      padding-right: 15px;
    }

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .free-contest-col {
    padding-right: 0;

    @media (min-width:770px) and (max-width:1199px) {
      padding-left: 0px;
      padding-right: 15px;
    }

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .paid-contest-col {
    padding-right: 0;

    @media (min-width:770px) and (max-width:1199px) {
      padding-left: 0px;
    }

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .Dropped-user-col {
    padding-right: 0px;

    @media (min-width:992px) and (max-width:1199px) {
      padding-left: 0px;
      padding-left: 15px;
      padding-right: 0;
    }

    @media (max-width:991px) {
      margin-top: 10px;
      padding-left: 0px;
    }

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0;
    }
  }
}

.dashboard-user-detail {

  // height: 16%;
  @media (max-width:1199px) {
    height: auto;
  }
}

.dashboard-contest-data {

  // height: 20%;
  @media (max-width:1199px) {
    height: auto;
  }
}

.deposit-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px 0px 0px;
}

.day-filter {
  width: 100px;
  height: 36px !important;
  border: 1px solid #D3D6DF;
  cursor: pointer;
  // background:  #F6F7F9;
}

.common-box-dashboard {
  background: #fff;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
  position: relative;

  .common-box-dashboard-div {
    padding: 16px;

    .dashboard-heading {
      font-weight: 700;
      font-family: Roboto;
      margin-bottom: 10px;
      font-size: 20px;
    }

    .text-label {
      font-weight: 400;
      font-family: Roboto;
      font-size: 14px;
      color: #7A839F;
      margin: 0;
    }

    .text-label-deposit {
      padding: 4px 12px;
      background-color: $badge-review-bgcolor;
      width: fit-content;
      font-size: 14px;

      span {
        color: $badge-review
      }
    }

    .text-label-withdraw {
      padding: 4px 12px;
      background-color: $badge-pending-bgcolor;
      width: fit-content;
      font-size: 14px;

      span {
        color: $badge-pending
      }
    }

    .text-label-free-content {
      padding: 4px 12px;
      background-color: $badge-success-bgcolor;
      width: fit-content;
      font-size: 14px;

      span {
        color: $badge-success
      }
    }

    .text-label-paid-content {
      padding: 4px 12px;
      background-color: $badge-live-bgcolor;
      width: fit-content;
      font-size: 14px;

      span {
        color: $badge-live
      }
    }
  }

  .user-played-heading {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }
  }

  .user-detail-row {
    margin-bottom: 16px;
  }
}

//    ------ for dashboard redirct ------
.common-box-dashboard {
  background: #fff;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
  position: relative;

  .common-box-dashboard-button {
    padding: 16px;
    background: $white;
    color: $input-placeholder-color;
    border: none;
    cursor: pointer;

    &:active {
      background: $white;
      color: $input-placeholder-color;
    }

    .dashboard-heading {
      font-weight: 700;
      font-family: Roboto;
      margin-bottom: 10px;
      font-size: 20px;
      text-align: left;
      color: black;
    }

    .text-label {
      font-weight: 400;
      font-family: Roboto;
      font-size: 14px;
      color: #7A839F;
      margin: 0;
      text-align: left;
    }

  }

  .user-played-heading {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
    }
  }

  .user-detail-row {
    margin-bottom: 16px;
  }
}

//    ------ End dashboard redirct ------

.total-winning-col {
  padding-right: 7px;

  @media (max-width:769px) {
    padding: 16px;
  }
}

.total-losing-col {
  padding-left: 7px;

  @media (max-width:769px) {
    padding: 16px;
  }
}

.total-winning-box {
  background: #9747FF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
  position: relative;

  .text-label {
    background-color: #F3EBFF;
    padding: 4px 12px;
    width: fit-content;
    margin-bottom: 16px;

    span {
      font-size: 16px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      color: #9747FF;
    }
  }

  .dashboard-heading {
    color: $white;
  }
}

.total-losing-box {
  background: #F1416C;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
  position: relative;

  .text-label {
    background-color: #FEECF0;
    padding: 4px 12px;
    width: fit-content;

    span {
      font-size: 16px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      color: #F1416C;
      margin-bottom: 0;
    }
  }

  .dashboard-heading {
    color: $white;
  }
}

.versioning {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: -3px;
  right: 0px;

  .time {
    color: #7A839F;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    text-align: end;
    padding-right: 16px;
  }

  .version-label {
    color: #7A839F;
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-align: end;
    padding-right: 16px;
  }
}

.other-details-heading {
  font-size: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.revenue-details {
  padding: 16px;
  background: #E6EEFE;

  .revenue-data {
    h1 {
      padding: 16px 0px;
      font-size: 20px;
      line-height: 0px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
    }

    canvas {
      height: 400px !important;
      width: 100% !important;
    }

    .line-chart {
      background-color: $white
    }
  }

  .play-return-col {
    padding-left: 0px;
    padding-right: 7px;

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .cancel-league-col {
    padding-right: 0px;
    padding-left: 7px;

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .bot-winner-col {
    padding-left: 0px;
    padding-right: 7px;

    @media (max-width:769px) {
      margin-bottom: 10px;
      padding: 0px;
    }
  }

  .play-return,
  .cancel-league,
  .bot-winner,
  .real-winner {
    padding: 16px;
    background: #fff;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
    position: relative;


    h3 {
      padding: 4px 12px;
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      width: fit-content;
      margin-bottom: 10px !important;

    }

    h2 {
      margin-bottom: 5px;
      font-size: 20px;
    }

    p {
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      color: #7A839F;
      margin-bottom: 0px;
    }
  }

  .play-return {
    h3 {
      background-color: $badge-review-bgcolor ;
      color: $badge-review;
    }
  }

  .cancel-league {
    h3 {
      background-color: $badge-live-bgcolor;
      color: $badge-live
    }
  }

  .bot-winner {
    h3 {
      background-color: $badge-live-bgcolor;
      color: $badge-live
    }
  }

  .real-winner {
    h3 {
      background-color: $badge-upcoming-bgcolor;
      color: $badge-upcoming
    }
  }

  .revenue-details-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }
}

.sport-details {
  border: 1px solid #CEDDFD;
  background-color: $white;

  &-header {

    h3 {
      margin: 0;
      font-size: 20px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 16px 0px;
  }

  &-sport-name {
    display: flex;
    gap: 16px;
    width: 100%;
    border-bottom: 1px solid #CEDDFD;
    padding: 8px 16px 0px 16px;
    flex-wrap: wrap;

    li {
      list-style: none;
      padding: 4px 16px;
      font-size: 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 8px;
      cursor: pointer;
    }

    .active-sport {
      border-bottom: 2px solid #3B77F7;
      color: #3B77F7
    }
  }

  .live-match-col {
    padding-right: 2px;

    .live-match {
      border: 1px solid var(--accent-pink-500, #F1416C);
      padding: 16px;

      h2 {
        color: #495057;
        font-size: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
      }

      p {
        margin: 0;
        color: #7A839F;
        font-size: 14px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .upcoming-match-col {
    padding-right: 2px;
  }

  .upcoming-match {
    border: 1px solid #CEDDFD;
    padding: 16px;

    @media (max-width:769px) {
      margin-top: 8px;
    }

    h2 {
      color: #495057;
      font-size: 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
    }

    p {
      margin: 0;
      color: #7A839F;
      font-size: 14px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
    }
  }

  .sport-details-col {
    padding: 8px;
  }

}

.dashboard-details {
  height: 100%;
}

.dashboard-deposit {
  @media (max-width:1199px) {
    height: auto;
  }
}