/* General Styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
    color: #333;
  }
  
  .chat-app {
    display: flex;
    height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Sidebar Styling */
  .sidebar {
    width: 320px;
    background-color: #ffffff;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #4a76a8;
    color: white;
    border-bottom: 1px solid #ddd;
    align-items: center;
  }
  
  .sidebar-header h2 {
    font-size: 20px;
    font-weight: 500;
  }
  
  .add-contact {
    background-color: #fff;
    color: #4a76a8;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  
  .add-contact:hover {
    background-color: #f0f0f0;
  }
  
  .contact-list {
    padding: 0;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .contact {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .contact:hover {
    background-color: #f8f9fa;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  
  .contact-info h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .contact-info p {
    margin: 0;
    color: #888;
    font-size: 14px;
  }
  
  .time {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
  }
  
  /* Chat Window Styling */
  .chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fc;
  }
  
  .chat-header {
    padding: 26px;
    background-color: #ffffff;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .chat-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
  
  .chat-header-controls button {
    background: none;
    border: none;
    font-size: 24px;
    color: #aaa;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .chat-header-controls button:hover {
    color: #333;
  }
//   .messages {
//     flex-grow: 1;
//     overflow-y: auto;
//     padding-bottom: 60px; /* Ensure space for the input box */
// }
  /* Messages Styling */
  .messages {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
  }
  
  .message {
    margin-bottom: 15px;
    max-width: 60%;
    padding: 12px 18px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    display: inline-block;
    word-wrap: break-word;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }
  
  .sent {
    background-color: $theme-color;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 0;
    transition: background-color 0.2s;
  }
  
  .received {
    background-color: #eaeaea;
    color: #333;
    align-self: flex-start;
    border-bottom-left-radius: 0;
  }
  
  .sent:hover, .received:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .message-time {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    position: absolute;
    right: 10px;
    bottom: -15px;
  }
  
  /* Message Input Styling */
  .message-input {
    display: flex;
    padding: 15px 20px;
    background-color: #ffffff;
    border-top: 1px solid #eee;
    position: fixed;
    bottom: 0;
    left: 36%;
    right: 4%;
    z-index: 1000;
    // display: flex;
    // padding: 15px 20px;
    // background-color: #ffffff;
    // border-top: 1px solid #eee;
    // position: relative;
  }
  




  .message-input input {
    flex: 1;
    padding: 12px 18px;
    border: none;
    // border-radius: 20px;
    background-color: #f1f3f6;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;
  }
  
  .message-input input:focus {
    background-color: #e9edf1;
    outline: none;
  }
  
  .message-input button {
    background-color: #4a76a8;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 12px;
    margin-left: 10px;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .message-input button:hover {
    background-color: #3c5f87;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .message-input button:active {
    background-color: #2f4c70;
    box-shadow: none;
  }
  