.theme-image {
    .theme-photo {
        margin: 0 0px 0px 0;
        display: inline-block;
        justify-content: center;

        .theme-img {
            height: 300px;
            width: 900px;

            .custom-img {
                height: 292px;
                width: 900px;
                object-fit: contain;
                position: relative;
                z-index: 1;

                &-default {
                    width: 72px;
                    height: 72px;
                }
            }

            margin-bottom: 14px;
            padding: 2px;
            border:1px solid #CEDDFD;
            border-radius: 0px;
            position: relative;

            .side-label {
                padding: 4px 6px;
                background: $theme-color;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color:
                    #8C94AB;
                position: absolute;
                right: -2px;
                bottom: -2px;
                border-radius: 4px;
            }

            .remove-img-label {
                position: absolute;
                right: 2px;
                top: 2px;
                cursor: pointer;
                ;
                z-index: 999;
            }

            .remove-img-label-prize {
                position: absolute;
                right: 5px;
                top: 5px;
                cursor: pointer;
                backdrop-filter: blur(2px);
            }

            &-default {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #D3D6DF;
                height: 300px;
                width: 900px;
                padding: 2px;
                border-radius: 0px;

                .remove-img-label {
                    position: absolute;
                    right: 22px;
                    top: 5px;
                    cursor: pointer;
                    backdrop-filter: blur(2px);
                }

                .remove-img-label-prize {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    cursor: pointer;
                    backdrop-filter: blur(2px);
                }

            }
        }
    }
    .custom-file {
        position: relative;
        width: 100px; /* Adjust size as needed */
        height: 120px; /* Adjust size to accommodate text */
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    
    .custom-file-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
    
    .upload-image {
        width: 100%; /* Adjust size as needed */
        height: auto; /* Maintain aspect ratio */
    }
    
    .upload-text {
        margin-top: 8px; /* Adjust space between image and text */
        color: #8C94AB;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        text-align: center;
    }
    .new-theme{
        .custom-img-default{
            margin-top:90px
        }
    
    }
        
}

.document-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;


    .hide-Edit-button {
        display: none;
    }
}

.theme-image-2 {
    .theme-photo {
        margin: 0 0px 0px 0;
        display: inline-block;
        justify-content: center;

        .theme-img {
            height: 300px;
            width: 900px;

            .custom-img {
                height: 292px;
                width: 900px;
                object-fit: contain;
                position: relative;
                z-index: 999;

                &-default {
                    width: 72px;
                    height: 72px;
                }
            }

            margin-bottom: 14px;
            padding: 2px;
            border:1px solid #CEDDFD;
            border-radius: 0px;
            position: relative;

            .side-label {
                padding: 4px 6px;
                background: $theme-color;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color:
                    #8C94AB;
                position: absolute;
                right: 2px;
                bottom: 2px;
                border-radius: 4px;
                z-index: 1000;
            }

            .remove-img-label {
                position: absolute;
                right: 2px;
                top: 2px;
                cursor: pointer;
                ;
                z-index: 999;
            }

            &-default {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #D3D6DF;
                height: 300px;
                width: 900px;
                padding: 2px;
                border-radius: 0px;

                .remove-img-label {
                    position: absolute;
                    right: 22px;
                    top: 5px;
                    cursor: pointer;
                    backdrop-filter: blur(2px);
                }

            }
        }
    }

    .custom-file {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 0px;
        cursor: pointer;
        z-index: 2;

        input {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            top: 60%;
        }

        label {
            margin: 0;
            padding: 0 0 0 24px;
            font-size: 0;
            height: initial;
            background: transparent;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            border: none;

            &::after {
                content: "" !important;
                position: static;
                height: auto;
                padding: 0;
                background: transparent;
                font-size: 16px;
                color: #8C94AB;
                font-family: Roboto;
                font-size: 14px;
            }
        }
    }
}



.table {
    .theme-image {
        height: 56px;
        max-width: 56px !important;
        border-radius: 100%;
    }

    .table-image {
        height: 46px;
        max-width: 46px !important;
        border-radius: 100%;
    }
}

/****** User Module CSS *****/
.common-detail {
    .common-item {
        margin-bottom: 40px;
    }

    h2 {
        margin-bottom: 40px;

        @media only screen and (max-width: 480px) {
            margin-bottom: 10px;
        }
    }

    .footer-btn {
        margin-top: 24px;

        button {
            margin: 0 12px;
            min-width: 168px;
        }
    }
}


.theme-image-popup {
    background-color: var(--background-primary);
    height: 300px;
    overflow: hidden;
    padding: 1em;
    position: relative;

    // .theme-photo      { margin: 0 0px 0px 0; display: inline-block; justify-content: center;
    .theme-img {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        text-align: center;

        // height: 300px; width: 900px;
        .custom-img {

            // height: 292px; width: 900px;
            // object-fit: contain;
            // position: relative;
            // z-index: 1;
            &-default {
                width: 72px;
                height: 72px;
            }

            // img{
            //     height: 72px;
            //     width: 72px;
            // }
        }

        margin-bottom: 14px;
        padding: 2px;
        border:1px solid #CEDDFD;
        border-radius: 0px;
        position: relative;

        .side-label {
            padding: 4px 6px;
            background: $theme-color;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            color: #8C94AB;
            position: absolute;
            right: -2px;
            bottom: -2px;
            border-radius: 4px;
        }

        .remove-img-label {
            position: absolute;
            right: 2px;
            top: 2px;
            cursor: pointer;
            ;
            z-index: 999;
        }

        .remove-img-label-prize {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            backdrop-filter: blur(2px);
        }

        &-default {
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            // border: 2px dashed #D3D6DF;
            // height: 300px;
            // width: 900px;
            // padding: 2px;
            // border-radius: 0px;

            margin-bottom: 14px;
            padding: 2px;
            border: 2px dashed #D3D6DF;
            border-radius: 0px;
            position: relative;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100%;
            text-align: center;

            .remove-img-label {
                position: absolute;
                right: 22px;
                top: 5px;
                cursor: pointer;
                backdrop-filter: blur(2px);
            }

            .remove-img-label-prize {
                position: absolute;
                right: 5px;
                top: 5px;
                cursor: pointer;
                backdrop-filter: blur(2px);
            }

        }

        // }
    }

    .custom-file {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 0px;
        cursor: pointer;
        z-index: 2;
        color: #8C94AB;

        input {
            position: absolute;
            height: 100%;
            width: 100%;

            opacity: 0;
            cursor: pointer;
        }

        &::before {
            content: "Upload Image";
            display: block;
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            top: 60%;
        }

        label {
            margin: 0;
            padding: 0 0 0 24px;
            font-size: 0;
            height: initial;
            background: transparent;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            border: none;

            &::after {
                content: "" !important;
                position: static;
                height: auto;
                padding: 0;
                background: transparent;
                font-size: 16px;
                color: #8C94AB;
                font-family: Roboto;
                font-size: 14px;
            }
        }
    }
}
