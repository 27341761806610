.edit-profile {
    margin: 2%;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    .name-card {
        width: 45%;
        background-color: $white;
    }
    .change-pass-card {
        padding: 2%;
    }
}