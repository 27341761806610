$theme-font: 'Roboto', sans-serif;
$theme-font-color: #11272E;
$theme-revamp:#20294C;
// $theme-revamp:#0844C4;
$theme-color: #3E4F93;
$border-color:#CEDDFD;;
$danger: #FF1E1E;
$danger-status: #D3D6DF;
$warning: #FF870B;
$success-color: #16AD28;
$success-message:#39C679;
$success-status-color:#3E4F93;
// $success-status-color:#145CF6;
$hightlight: #FB378E;
$hightlight-bg: #FFE238;
$white: #FFFFFF;
$black: #000;
$font-light-color: #A6A6A6;
$mute-color: #7F7F7F;
$border-dark: #B2B2B2;
$success-bg: rgba(0, 174, 66, 0.14);
$common-transition: all 0.3s ease-in-out;
$gray: #5F6B7A;
$darkGray: #212A34;
$blue: #189EE1;
$hover-theme:#2D3A6C;
// $hover-theme:#3B77F7;
$header-background : #F6F7F9;
$table-background :#F5F8FF;
$btn-background :#3E4F93;
$page-background:#F5F8FF;
$label-font-color: #3A3F50;
$input-placeholder-color:#7A839F;
$radio-btn-border:#8C94AB;
$radio-btn-text-color:#667085;
$table-head:#E6EEFE;
$prize-popup-txt:#545B73;
$popup-header-text:#22262F;
$edit-input-color:#22262F;
$email-temp-bold : #15171E;
$radio-div-color:#F1F3F9;
$required-color:#CC0000;
$card-title-color:#7A839F;
$lineups-out:#13CA26;
$img-border:#7FA7FA;



// Badges Color //
$badge-success:#39C679;
$badge-success-bgcolor:#E6FAEF;

$badge-danger:#F1416C;
$badge-danger-bgcolor:#FEECF0;

$badge-live:#FF3D3D;
$badge-live-bgcolor:#FFF5F5;

$badge-pending:#E78B2F;
$badge-pending-bgcolor:#FCF2E8;

$badge-upcoming:#9747FF;
$badge-upcoming-bgcolor:#F3EBFF;

$badge-review:#2C9AFF;
$badge-review-bgcolor:#F1F4FF;


@mixin transition($property) {
    -webkit-transition: $property;
    -ms-transition: $property;
    transition: $property;
}
@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}
@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    box-shadow: $property;
}
@mixin filter($property) {
    -webkit-filter: $property;
    filter: $property;
}
@mixin flex-shrink($property) {
    -webkit-flex-shrink: $property;
    flex-shrink: $property;
}

%after-before       { content: ""; position: absolute; display: block; }
%theme-gradient     { background-image: linear-gradient(90deg, #AB38E8 0%, #7B37FE 100%); }
