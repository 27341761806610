/******* User Module CSS ******/

.main-content{
}
.common-form-block {
    margin: 24px auto;
    padding: 34px 24px;
    width: 55%;
    background: #fff;
    @include box-shadow(0 0 60px rgba(18, 38, 63, 0.08));
    border-radius: 8px;
    overflow: hidden;
    border-radius: 0px;

    h2 {
        margin-bottom: 34px;
    }
    .form-control{
        &:focus{
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(206, 221, 253, 0.32);
        }
    }
    .react-datepicker-wrapper{
    .form-control{
        &:focus{
            box-shadow: none !important;
        }
    }
}
    .theme-btn {
        margin-top: 10px;
    }

    &.profile-form {
        padding: 0;
        
        .profile-head {
            margin-bottom: 70px;
            padding: 60px;
            background: $theme-color;
            position: relative;
        }

        .profile-block {
            position: absolute;
            left: 50%;
            bottom: 0;
            @include transform(translate(-50%, 50%));

            .profile-pic {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                border: 5px solid #fff;
            }

            .file-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 26px;
                width: 26px;
                background: $theme-color url(../../images/camera-icon.svg) no-repeat center center / 55% auto;
                @include transform(translate(0%, -50%));
                border-radius: 50%;
                border: 2px solid #fff;
                cursor: pointer;
                z-index: 2;

                input {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }

        .form-inner {
            padding: 10px 24px 34px;
        }
    border-radius: 0px;
    overflow: hidden;

    h2 {
        margin-bottom: 34px;
    }

    .theme-btn {
        margin-top: 10px;
    }

    &.profile-form {
        padding: 0;

        .profile-head {
            margin-bottom: 70px;
            padding: 60px;
            background: $theme-color;
            position: relative;
        }

        .profile-block {
            position: absolute;
            left: 50%;
            bottom: 0;
            @include transform(translate(-50%, 50%));

            .profile-pic {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                border: 5px solid #fff;
            }

            .file-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 26px;
                width: 26px;
                background: $theme-color url(../../images/camera-icon.svg) no-repeat center center / 55% auto;
                @include transform(translate(0%, -50%));
                border-radius: 50%;
                border: 2px solid #fff;
                cursor: pointer;
                z-index: 2;
                &::after{
                    content:' upload';
                }
                input {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }

        .form-inner {
            padding: 10px 24px 34px;
        }
    }


.edit-label{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $label-font-color;
}
.input-box{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.input-box-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 100px;
}

.remove-img-label {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}

    @media only screen and (max-width: 480px) {
        width: 100%
    }
   
}
@media (min-width: 360px) and (max-width: 991px){
    width: 70%;
}
.max-disCount{
    @media (max-width: 1191px) {
        margin-top: 16px;
    }
}
}
.common-form-block-affiliate{
    margin: 24px auto;
    padding: 34px 24px;
    width:45%;
    background: #fff;
    @include box-shadow(0 0 60px rgba(18, 38, 63, 0.08));
    border-radius: 8px;
    overflow: hidden;
    border-radius: 0px;

    h2 {
        margin-bottom: 34px;
    }
    @media only screen and (max-width: 480px) {
        width: 100%
    }
    @media (min-width: 360px) and (max-width: 991px){
        width: 70%;
    }
}


// /******* User Module CSS ******/



































