.alert{
    position: fixed;
    z-index: 9999;
    top: 50px;
    right: 10px;
    color: rgb(2, 2, 2);
    width: 300px;
    animation: alert-right .5s;
    border-radius: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.success-toast-div {
  display: flex;
  gap: 10px;
  border-radius: 0px;

  .img-box {
      background-color: $badge-live;
      min-width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .success-box {
      background-color: $badge-success;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
  }   
  .message{
      padding: 13px 0px;
      font-size: 14px;
  }
}
.alert-dismissible .close{
  display: none;
}
.alert-box{
  align-items: center;
  margin-bottom: 0;
  padding: 0px 5px 0px 0px;
  .alert-image{
    width: 20%;
    object-fit: cover;
    height: 100%;

    img{
      width: 100%;
      height: 100%;
    }
  }
  p{
    margin-bottom: 0;
    width: 95%;
    font-size: 14px;
  }
}
.alert-dismissible{
  padding: 0px 10px 0px 0px !important;
}
.alert-close {
    animation: alert-left .5s;
}

.sucess-alert {
    background-color:$white;
    color: black;
}

.fail-alert {
  background-color: $white;
  color: black;
}

@keyframes alert-right {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes alert-left {
	from {
        transform: translateX(0);
	}
	to {
        transform: translateX(100%);
	}
}