.columns-name {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 2px;
  font-weight: 600;
}

.select {
  background-color: #007bff;
  color: white;
}

.unSelect {
  color: black;
  background-color: #dcdcdc;
}

.arrange{
  padding: 8px 4px 0px 4px;
  color: #545C73;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.arrange-1{
  padding: 0px 4px 0px 4px;
  color: #545C73;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
}

.dnd-column{
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 2px;
  border: 1px solid #ceddfd;
  border-radius: 0px;

  img{
    height: 16px;
    width: 16px;
  }
  .column-name{
    display: inline-block;
    margin-left: 10px;
    color: #7A839F;
    }
}

.columns-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 4px 16px 16px 16px;
  background-color: $badge-review-bgcolor;
  border-radius: 0px;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.column-item {
  padding: 10px 20px;
  margin: 5px;
  background-color: $white;
  border-radius: 0px;
  cursor: pointer;
  border: 1px solid $badge-review;
}

.column-item.selected {
  background-color: $success-status-color;
  color: $white;
  border: none;
}
.column-btn{
  border-radius: 0px;
  background: $theme-revamp;
  color: $white;
  font-weight: 500;
  grid-gap: 24px;
  padding: 12px;
  border: none
}

.selected-col{
  // background-color: rgba(0, 123, 255, 0.5);
  background-color: #F1F4FF;
  color: $white;
  border: 1px solid $badge-review;
}
