/******* User Module CSS ******/
.management-section {
    background: $page-background;

    .header-block-main {
        
        padding: 24px;
    }
    .season-select{
        height: 44px;
        width: 156px;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;

        .css-16xfy0z-control{
            height: 44px;
            border-radius: 0px;
        }
        .css-13cymwt-control{
            border: 1px solid #CEDDFD;
            &:hover {
                min-height: 44px;
                font-size: 14px;
                line-height: 16px;
                font-style: normal;
                font-weight: 400;
            }         
        }
        .css-t3ipsp-control{
            border-radius: 0px;
            border: 1px solid #CEDDFD;
            min-height: 44px;
            &:hover{
                border-radius: 0px;
                border: 1px solid #CEDDFD;
                min-height: 44px;
            }
        }
    }

      
    .form-control{
        &:focus{
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(206, 221, 253, 0.32);
        }
    }
    .match-details-radio {
        padding: 24px 0px 24px 24px;

        @media (min-width:480px) and (max-width:991px){
            padding: 24px
        }
    }

    .match-details {
        padding: 24px 24px 0px 24px;

        h2 {
            margin: 0px;
            font-size: 20px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            padding: 16px 50px 16px 24px;
            border-bottom: 1px solid $border-color;
            position: relative;
        }

        .input-group-text {
            height: 44px;
            background-color: $header-background;
        }
    }
    .carer-Icons{
        position: absolute;
        right: 40px;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .header-block {
        .sports-heading {
            margin: 0px !important;
            font-size: 28px;
            display: flex;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            
                    @media (min-width:851px) and (max-width:982px) {
                        font-size: 20px;
                    }

                    @media (max-width:850px)  {
                        font-size: 16px;
                    }

            P {
                font-size: 20px;
                color: #3B77F7;
                margin-left: 10px;
                margin-bottom: 0px;

                @media (max-width:981px)  {
                    font-size: 14px;
                }
            }
        }

        h2 {

            @media only screen and (max-width: 480px) {
                margin-bottom: 20px;
            }
        }

        .form-group {
           
        }

        .select-sports {
            height: 44px;
            width: 160px;
            border: 1px solid $border-color;
            border-radius: 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px; 
            font-style: normal;
            align-items: center;
            letter-spacing: -0.003em;
            color: #545B73;

            &:focus{
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(206, 221, 253, 0.32);
        }
        }

        .select-user-header {
            height: 44px;
            width: 160px;
            border: 1px solid $border-color;
            border-radius: 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            align-items: center;
            letter-spacing: -0.003em;
            color: #545B73;
            // margin-right: 30px;
        }

        .user-select {
            min-width: 192px;
        }
    }

    .css-1fhf3k1-control {
        height: 44px;
        border-radius: 0px;
    }

    .custom-input-field {
        height: 44px;
        border: 1px solid $border-color;
        border-radius: 0px;
    }

    .form-group-match {
        margin-top: 15px;
    }

    .input-group-text {
        border-radius: 0px !important;
        height: 44px;
    }

    .marge-filter{
        width: 165px;
        height: 44px;
        margin-top: 0rem !important;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
    }
    .format-f,
    .status-s,
    .provider-p {
        width: 140px;
        height: 44px;
        margin-top: 0rem !important;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
    }
    .substitute,
    .searchFilter{
        width: 170px;
        height: 44px;
        margin-top: 0rem !important;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
    }
    // .searchFilter{
    //     width: 160px;
    //     height: 44px;
    //     margin-top: 0rem !important;
    //     border-radius: 0px;
    //     border: 1px solid $border-color;
    //     color: $input-placeholder-color;
    // }

    .leagueFilter{
        height: 44px;
        margin-top: 0rem !important;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
    }

    .league-main-heading {
        margin: 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }
    
    .css-13cymwt-control,
    .css-1pahdxg-control {
        min-height: 44px;
        width: 100%;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
       
    }
    .css-1pahdxg-control:hover {
        height: fit-content;
        width: auto;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
        box-shadow: 0 0 0 1px $border-color;
    }

    .entries-selection {
        p {
            margin: 0 8px 0 0;
        }

        .form-group {
            margin: 0;
        }
    }

    .btn-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        >* {
        }

        img {
            cursor: pointer;
        }
    }

    .date-range {
        position: relative;
        height: 44px;
        width: 252px;

        img {
            margin: 0;
            position: relative;
            left: 0px;
            top: 50%;
            @include transform (translateY(-50%));
        }

        &:hover img {
            @include filter(brightness(0));
        }

        .date-input {
            width: 100%;
        }
    }

}
.data-range-tournament{
        position: relative;
        height: 44px;
        width: 252px;

        img {
            margin: 0;
            position: relative;
            left: 0px;
            top: 50%;
            @include transform (translateY(-50%));
        }

        &:hover img {
            @include filter(brightness(0));
        }

        .date-input {
            width: 100%;
        }
}
.profile-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .profile-image {
        position: relative;
        width: 200px;
        height: 200px;
        border: 2px dashed #D3D6DF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .system-user-profile-pic{
            height: 190px;
            width: 190px;
            border-radius: 50%;
        }
      
    &-default{
        position: relative;
        width: 200px;
        height: 200px;
        border: 1px solid #CEDDFD;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        object-fit: contain;
    }
        .file-btn2 {
            position: absolute;
            right: 0px;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            top: 0px;
            left: 0px;
            bottom: 0px;
            white-space: nowrap;
            &::after{
                content: "Upload Photo";
                color: #8C94AB;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                position: absolute;
                top: 135px;
                left: 60px;
            }
            input {
                position: relative;
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
     
    }

   
    .user-remove-img-label {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    } 
    .remove-img-label {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        backdrop-filter: blur(2px);
    }
    .remove-img-label-user{
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        backdrop-filter: blur(2px);
    }

    .profile-pic {
        height: 72px;
        width: 72px;
    }
    .user-profile-pic{
        height: 190px;
        width: 190px;
        border-radius: 50%;
    }

    .file-btn {
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        z-index: 2;
            &::after{
                position: relative;
                content: "Upload Image";
                color: #8C94AB;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                top: 40px;
            }
        input {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: red;
            opacity: 0;
            cursor: pointer;
        }
    }

    .profile-pic-systemuser {
        height: 160px;
        width: 160px;
        border-radius: 50%;
    }

    small {
        line-height: inherit;
    }
}
.profile-block-two {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .profile-image {
        position: relative;
        width: 200px;
        height: 200px;
        border: 2px dashed #D3D6DF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;


        @media (width:769px) and  (max-width:900px) {
            width: 100px;
            height: 100px;
        }
        .file-btn2 {
            position: absolute;
            right: 0px;
            height: 100%;
            width: 100%;          
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            top: 0px;
            left: 0px;
            bottom: 0px;
            white-space: nowrap;
            &::after{
                content: "Upload Image";
                color: #8C94AB;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                position: absolute;
                top: 135px;
                left: 60px;

                @media (width:769px) and (max-width:900px) {
                    top: 55px;
                    left: 13px;
                    font-size: 12px;
                }
            }
            input {
                position: relative;
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
     
    }

    .system-remove-img-label{
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
   
    .remove-img-label {
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
        backdrop-filter: blur(2px);
    }
    .system-user-profile-pic{
        height: 190px;
        width: 190px;
        border-radius: 50%;
    }
    .profile-pic {
        height: 72px;
        width: 72px;

        @media (width:769px) and  (max-width:900px) {
            width: 30px;
            height: 30px;
        }
    }

    .file-btn {
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        z-index: 2;
            &::after{
                position: relative;
                content: "Upload Photo";
                color: #8C94AB;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                top: 40px;
            }
        input {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: red;
            opacity: 0;
            cursor: pointer;
        }
    }

    .profile-pic-systemuser {
        height: 160px;
        width: 160px;
        border-radius: 50%;
    }

    small {
        line-height: inherit;
    }
}

.document-list {
    white-space: nowrap;
    .item {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $border-color;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        .btn-link {
            margin-right: 20px;
            text-transform: uppercase;
            font-weight: 500;

            &:last-child {
                margin: 0;
            }
        }
        .danger-btn-rej {
            display:flex;
            span{
                color: $danger;
                display: block;
                // width:90px;
            }
        }

    .theme-image{
        .lable-Upload-Image{
            background: transparent; font-size: 14px; color: #8C94AB;
            font-weight: 500;
        }
        position: relative;
        width: 100%;
        .theme-photo{
            width: 100%; 
            .theme-img{
                height: 230px; width: 100%;
                z-index: 1;
                .custom-img {
                    height: 225px; width: 100%;
                    object-fit: contain;
                    &-default{
                        width: 72px;
                        height: 50px;
                    }
                }
                &-default{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 2px dashed #D3D6DF;  
                    height: 230px; width: 100%;
                    padding: 2px;
                }
               
            }
            .remove-img-label {
                position: absolute;
                right: 0;
                bottom: 0;
                cursor: pointer;
                backdrop-filter: blur(2px);
            }
            .custom-file {  cursor: pointer;position: absolute;
                right: 0px;
                top: 0px;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 0px;
                cursor: pointer;
                z-index: 2;
                bottom:0px;
                left:0px;
                input       { position: absolute;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    top: 0px;
                    cursor: pointer;}
                &::before   { content: ""; display: block; position: absolute; top: 50%; @include transform (translateY(-50%)); left: 0; height: 20px; width: 20px; }
                label       { margin: 0px;padding: 0px; top: 50px; font-size: 0; height: initial; background: transparent; display: inline-block; vertical-align: middle; position: relative; border: none; 
                    &::after    { content: "" !important; position: static; height: auto; padding: 0; background: transparent; font-size: 16px; color: #8C94AB; font-family: Roboto;font-size: 14px;}
                }
            }
        }
    }
 
    .kyc-button{
        .approve,.reject{
            @media  (min-width:481px) and (max-width:768px) {
                padding: 0px;
                margin-top: 5px;
            }
        }
    }

   
}
    .adhar-Img{
        width: 100%;
        .theme-photo{
            width: 100%; 
            .theme-img{
                height: 230px; width: 100%;
                .custom-img {
                    height: 225px; width: 100%;
                    object-fit: contain;
                    &-default{
                        width: 72px;
                        height: 50px;
                    }
                }
                &-default{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 2px dashed #D3D6DF;  
                    height: 230px; width: 100%;
                    padding: 2px;
                    position: relative;
                    .side-label{
                        color: #8C94AB;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        margin-top: 8px;
                    }
                }
            }
        }
      
    }
    .doc-photo {
        .doc-img {
            margin-bottom: 14px;  
            border: 1px solid black;
            padding: 2px;
            border-radius: 4px;
            position: relative;
            height: 125px;
            width: 200px;
            .custom-img {
                height: 118px;
                width: 200px;
            }

            .side-label {
                padding: 4px 6px;
                background: $theme-color;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color: #fff;
                position: absolute;
                right: -2px;
                bottom: -2px;
                border-radius: 4px;
            }
        }

        .doc-img2 {
            margin-bottom: 14px;
            padding: 2px;
            max-width: 2000px;
            height: 500px;
            border: 2px dashed $theme-color;
            border-radius: 4px;
            position: relative;

            .side-label {
                padding: 4px 6px;
                background: $theme-color;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color: #fff;
                position: absolute;
                right: -2px;
                bottom: -2px;
                border-radius: 4px;
            }
        }
        .doc-img2-img{
            margin-bottom: 14px;
            padding: 2px;
            max-width: 2000px;
            height: 500px;
            border: 2px dashed $theme-color;
            border-radius: 4px;
            position: relative;

            .side-label {
                padding: 4px 6px;
                background: $theme-color;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                color: #fff;
                position: absolute;
                right: -2px;
                bottom: -2px;
                border-radius: 4px;
            }
            img{
                height: 256px;
                width: 556px;
            }
        }
    }

    .remove-img-label {
        position: absolute;
        right: -10px;
        top: -10px;
        cursor: pointer;
        backdrop-filter: blur(2px);
    }

    .custom-file {
            width: initial;
            height: initial;
            position: relative;
            cursor: pointer;
   
        input {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: red;
            opacity: 0;
            cursor: pointer;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            @include transform (translateY(-50%));
            left: 0;
            height: 20px;
            width: 20px;
        }

        label {
            margin: 0;
            padding: 0 0 0 24px;
            font-size: 0;
            height: initial;
            background: transparent;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            border: none;

            &::after {
                content: "Edit" !important;
                position: static;
                height: auto;
                padding: 0;
                background: transparent;
                font-size: 16px;
                color: #000;
            }
        }
    }
}

.kyc-img {
    height: 400px;
}

.feedback-view {
    .item {
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }

        p {
            margin-bottom: 2px;
            color: #000;
            &.title {
                color: $font-light-color;
            }
        }
    }
}

.account-text {
    color: red;
}

.details-heading {

    padding: 16px 50px 16px 24px;
    background: white;
    border-bottom: 1px solid $border-color;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.series-row {
    padding: 10px;
}

.column-adjest{
    padding: 0 !important;
}
.match-edit-main-header{
    background: white;
    padding: 24px;
    display: flex;
    align-items: center;
    height: 80px;
    
    .match-heading{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;

        @media (max-width:1199px ) {
            font-size: 20px;
        }
    }

    
}
.match-edit-header{
    background: #F6F7F9;
    border: 1px solid #CEDDFD;
    padding: 24px;

}
.custom-input-filter{
    height: 44px;
    border: 1px solid $border-color;
    border-radius: 0px;
}

@media only screen and (max-width: 480px) {
    .management-section {
        padding: 10px;

        h2 {
            font-size: 20px;
        }

    }
}

.series-row {
    margin-left: 0;
    margin-right: 0;

    .css-13cymwt-control,
    .css-1pahdxg-control {
        height: 44px;
        width: 100%;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
    }

    .css-1pahdxg-control:hover {
        height: 44px;
        width: 100%;
        border-radius: 0px;
        border: 1px solid $border-color;
        color: $input-placeholder-color;
        box-shadow: 0 0 0 1px $border-color;
    }
}
.user-sys-team-form{
    padding: 0px 24px 24px 24px;
}
.user-team-text{
    font-weight: 400;  
    font-size: 14px;
    line-height: 16px;
    color:$input-placeholder-color;
    text-align: left;
}
.user-team-form{
    text-align: left;

    .user-team-label{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $label-font-color;
    }
}
.user-sys-team-body{
    padding: 0px 24px 24px 24px;
}

.user-team-form-checkbox{
    height: 50px;
    background-color: #F1F3F9 ;

    .user-team-form-check{
        text-align: start;
        display: flex;
        align-items: center;
        height: 100%;
        padding:0px 14px 0px 14px ;
    
        .user-check{
            margin:0;   
        }
        .user-check-label{
            margin-left:28px;
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color:$radio-btn-text-color
        }
    }
}


.withdrawals-modal-content,
.deposit-modal-content {
    .modal-content {
        width: 40%;
        border-radius: 0px;
        height: calc(100% - 20%);

        .modal-body {
            padding: 24px;
        }

        .modal-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #22262F;
        }
    }
}


.edit-bot-btn{
    padding-bottom: 24px;
    padding-left:24px;
}
.outline-bot{
    border-radius: 0px;
    background: white;
    color: $label-font-color;
    border: 2px solid $btn-background;
    height: 44px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.bot-field{
    background: white;
    width: 100%;
    height: auto;
    margin: 0px ;
    box-shadow: 0px 4px 16px rgba(122, 131, 159, 0.16);
    .edit-bots{
        text-align: center;
    }
    .create-bots{
        text-align: center;
        .sub-heading{
            border-bottom: 1px solid $table-head;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px 16px 24px;
            margin:0px;

            h3{
                margin: 0;
            }
        }
    }
    .bot-field-edit-header{
        border-bottom: 1px solid $table-head;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px 16px 24px;
        margin:0px;
        h3{
            margin-bottom: 0px;
        }
    }
}
.select-label-bot{
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $label-font-color;
    padding-left: 24px;
    padding-right: 24px;
}

.edit-bot-row{
    padding: 0px 8px 0px 8px;
}

.refer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: $badge-success-bgcolor;
    margin: 0px 24px 24px 24px;
    padding: 10px 16px;
    
    .refer-total{
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $badge-success;
    }
    .refer-list{
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: $badge-success;
        border-bottom: 1px solid $badge-success;

        .refer-code{
            color: $badge-success;
            border: none;
            background-color: $badge-success-bgcolor;
            padding:0px;

            span{
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                color: $badge-success;
            }
            &:active {
                background: $badge-success-bgcolor;
                color: $success-status-color;
            }
        }
        .not-refer-code{
            color: $badge-success;
            border: none;
            background-color: $badge-success-bgcolor;
            padding:0px;

            span{
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                color: $badge-success;
            }
            &:active {
                background: $badge-success-bgcolor;
                color: $success-status-color;
            }
        }
    }
}



